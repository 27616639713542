<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="title"
      :sub-title="subTitle"
    />
    <div class="notifications mt-4 px-4">
      <b-col
        v-for="(notification, index) in notifications"
        :key="index"
        class="bg-white py-5 rounded-14 mb-5 d-flex"
        md="12"
      >
        <b-avatar
          size="50"
          class="p-4 avatar mx-2"
          :class="notification.isSeen ? 'svg-icon' : 'svg-warning'"
          :variant="notification.isSeen ? 'light-icon' : 'light-warning'"
        >
          <notification-icon />
        </b-avatar>
        <div class="mx-2">
          <div class="mb-5">
            <h3
              class="
                notifications__title
                text-med-16 text-font-main
                d-inline
                mr-3
                text-font-main
              "
            >
              {{ notification.title }}
            </h3>
            <small
              class="notifications__time text-reg-12 text-font-secondary"
            >{{ notification.time }}</small>
          </div>
          <p class="text-med-16 text-font-secondary">
            {{ notification.message }}
          </p>
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      title: this.$i18n.t('notification.title'),
      subTitle: this.$i18n.t('notification.sub_title'),
      notifications: [
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: false,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: false,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
        {
          title: 'هذا النص هو مثال',
          message:
            'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد ',
          isSeen: true,
          time: 'منذ 5 دقائق',
        },
      ],
    };
  },
};
</script>
